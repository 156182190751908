
import loadMixin from '~/mixins/load.js';
import contentMixin from '~/mixins/content.js';

export default {
    mixins: [loadMixin, contentMixin],
    nuxtI18n: {
        paths: {
            en: '/',
            fi: '/',
            se: '/',
        },
    },
};
